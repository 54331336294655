import { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, Select, notification } from 'antd';
import MdiIcon from '@mdi/react';
import {
  mdiAccountCircleOutline,
  mdiLockOutline,
  mdiCellphoneMessage,
  mdiArrowLeft,
  mdiLockCheckOutline,
} from '@mdi/js';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Icon from '@app/components/icon';
import { firebase } from '@app/services/firebase';
import { FirebaseErrorResolverHint } from '@app/types';

import Logo from '../logo';

import styles from './auth.module.css';

const { Paragraph, Link: AntLink } = Typography;
const { Option } = Select;

export type AuthFormValueType = {
  username: string;
  password: string;
  phone: string;
  verifycode: string;
};

type AuthPropType = {
  showLogo?: boolean;
  showLogin?: boolean;
  loading?: boolean;
  showMultiFactorCodeInput?: boolean;
  showForgotPassword?: boolean;
  showVerifyMethods?: boolean;
  verifyMethods?: FirebaseErrorResolverHint[];
  onCodeVerify?: (code: string) => void;
  onLoggedInRequest?: (data: Omit<AuthFormValueType, 'phone' | 'verifycode'>) => void;
  onResetRequest?: (username: string) => void;
  onRecaptchaVerified?: (
    multiFactorHintUid: string,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
  ) => void;
};

// Auth Content
const AuthContent = ({
  showLogo = true,
  showLogin = false,
  loading = false,
  showMultiFactorCodeInput = false,
  showVerifyMethods = false,
  showForgotPassword = false,
  verifyMethods,
  onLoggedInRequest,
  onResetRequest,
  onRecaptchaVerified,
  onCodeVerify,
}: AuthPropType): JSX.Element => {
  const router = useRouter();
  const [disabledBtn, setDisabledBtn] = useState(true);
  const recaptcha = useRef<firebase.auth.RecaptchaVerifier | null>(null);
  // 2FA? for admin
  const onSignIn = (values: Omit<AuthFormValueType, 'phone' | 'verifycode'>) => {
    if (onLoggedInRequest) {
      onLoggedInRequest(values);
    }
  };

  const onVerifyMethod = (
    values: Omit<AuthFormValueType, 'username' | 'password' | 'verifycode'>,
  ) => {
    if (onRecaptchaVerified) {
      if (recaptcha.current) {
        onRecaptchaVerified(values.phone, recaptcha.current);
      }
    }
  };

  const onResetPassword = (
    values: Omit<AuthFormValueType, 'password' | 'phone' | 'verifycode'>,
  ) => {
    if (onResetRequest) {
      onResetRequest(values.username);
    }
  };

  const onCodeVerifySubmit = (
    values: Omit<AuthFormValueType, 'username' | 'password' | 'phone'>,
  ) => {
    if (onCodeVerify) {
      onCodeVerify(values.verifycode);
    }
  };

  useEffect(() => {
    if (showVerifyMethods) {
      recaptcha.current = new firebase.auth.RecaptchaVerifier('recapture-code', {
        size: 'normal',
        callback() {
          // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
          setDisabledBtn(false);
        },
        // eslint-disable-next-line func-names
        'expired-callback': function () {
          // Response expired. Ask user to solve reCAPTCHA again.
          notification.error({
            message: 'Verification expired.',
            description: 'Please click the checkbox again to validate.',
            duration: 5,
          });

          setDisabledBtn(true);
        },
      });
      recaptcha.current.render();
    }
  }, [showVerifyMethods]);

  return (
    <>
      {/* Generic login screen */}
      {showLogin && (
        <Form
          name="login"
          onFinish={onSignIn}
          // initialValues={{
          //   username: 'manager@kandycitycentre.lk',
          //   password: 'manager',
          // }}
        >
          {showLogo && <Logo theme="light" size={0.5} />}
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please enter your username' }]}>
            <Input
              prefix={<MdiIcon path={mdiAccountCircleOutline} size={1} className={styles.icon} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}>
            <Input.Password
              prefix={<MdiIcon path={mdiLockOutline} size={1} className={styles.icon} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item className={styles.forgotPasswordLink}>
            <Link href="/reset-password">
              <AntLink style={{ textAlign: 'center' }}>Forgot password?</AntLink>
            </Link>
          </Form.Item>
          <Form.Item className={styles.buttonWrapper}>
            <Button loading={loading} id="login-btn" type="primary" htmlType="submit" block>
              Log in
            </Button>
          </Form.Item>
        </Form>
      )}
      {/* Verify methods */}
      {showVerifyMethods && (
        <Form
          onFinish={onVerifyMethod}
          initialValues={{ phone: verifyMethods && verifyMethods.length && verifyMethods[0].uid }}>
          {showLogo && <Logo theme="light" size={0.5} />}
          <Form.Item>
            <Typography>
              <Paragraph className={styles.smsInstructions}>
                Please select your phone number to verify using SMS.
              </Paragraph>
            </Typography>
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: 'Please select your mobile number' }]}>
            {verifyMethods && verifyMethods.length > 0 && (
              <Select>
                {verifyMethods.map((item) => (
                  <Option key={item.uid} value={item.uid}>
                    {item.phoneNumber}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <div id="recapture-code" />
          </Form.Item>
          <Row>
            <Col flex={2}>
              <Button
                disabled={disabledBtn}
                type="primary"
                loading={loading}
                htmlType="submit"
                icon={<Icon path={mdiCellphoneMessage} size={0.8} />}
                block>
                Send Code
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {/* password reset */}
      {showForgotPassword && (
        <Form onFinish={onResetPassword}>
          {showLogo && <Logo theme="light" size={0.5} />}
          <Form.Item>
            <Paragraph className={styles.instructions}>
              Please enter your username and we will send you the instruction to reset your
              password.
            </Paragraph>
          </Form.Item>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please enter your username' }]}>
            <Input
              prefix={<MdiIcon path={mdiAccountCircleOutline} size={1} className={styles.icon} />}
              placeholder="Username"
            />
          </Form.Item>
          <Row justify="space-between" className={styles.buttonWrapper}>
            <Col flex={2}>
              <Button
                onClick={() => {
                  router.push('/login');
                }}
                type="default"
                icon={<Icon path={mdiArrowLeft} size={0.8} />}
                className={styles.resetLoginIcon}>
                Login
              </Button>
            </Col>
            <Col flex={3} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Reset Password
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {/* Show multifactor input */}
      {showMultiFactorCodeInput && (
        <Form onFinish={onCodeVerifySubmit}>
          {showLogo && <Logo theme="light" size={0.5} />}
          <Form.Item>
            <Paragraph className={styles.instructions}>
              Please enter the verification code we sent to your mobile.
            </Paragraph>
          </Form.Item>
          <Form.Item
            name="verifycode"
            rules={[{ required: true, message: 'Verification code required.' }]}>
            <Input
              prefix={<MdiIcon path={mdiCellphoneMessage} size={1} className={styles.icon} />}
              placeholder="Verification code"
              autoFocus
              maxLength={6}
              type="password"
            />
          </Form.Item>
          <Row>
            <Col flex={1}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<Icon path={mdiLockCheckOutline} size={0.8} />}>
                Verify
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

// Full Screen Auth - default component
export default function FullScreenAuth(props: AuthPropType): JSX.Element {
  return (
    <Row>
      <Col>
        <div className={styles.authContentWrapper}>
          <AuthContent {...props} />
        </div>
      </Col>
    </Row>
  );
}
